
import {defineComponent, ref} from "vue";
import Button from "@/components/UI/Button.vue";
import {useUser} from "@/store/pinia/userStore";
import {bus} from "@/modules/eventBus";
import {UploadFile, UploadProgressEvent, UploadRawFile} from "element-plus/es/components/upload/src/upload";
import {ElMessage} from "element-plus";
import {UserService} from "@/services/UserService";
import {Invitation} from "@/models/user/Invitation";
import UserEmergencyContactsCard from "@/components/User/Settings/UserEmergencyContactsCard.vue";
import {EmergencyContactService} from "@/services/EmergencyContactService";
import PhoneNumberModal from "@/components/modals/PhoneNumberModal.vue";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import MeasurementSettings from "@/components/SelfContained/MeasurementSettings.vue";
import Swal from "sweetalert2";
import {EmailAddressService} from "@/services/EmailAddressService";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {MeasurementSettings, UserEmergencyContactsCard, Button, PhoneNumberModal},
  setup() {
    const userStore = useUser()
    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Avatar picture must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    const phoneNumberModal = ref<InstanceType<typeof PhoneNumberModal>>();
    const addPhone = () => {
      phoneNumberModal.value?.invokeAdd();
    }
    return {
      userStore,
      imageUrl,
      handleAvatarSuccess,
      beforeAvatarUpload,
      phoneNumberModal,
      addPhone
    }
  },
  data() {
    return {
      photoIsLoading: false,
      photoStepComplete: false,
      subStep: 0,
      emergencyContacts: [] as Array<any>,
      measurementsComplete: false,
      emergencyContactsComplete: false,
      emailVerificationRequested: false
    }
  },
  mounted() {
    this.fetchContacts()
    bus.on('onboarding:emit:emergencycontacts', () => {
      this.fetchContacts()
    })
    bus.on('modals:cropper:handle:user-onboard', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  methods: {
    backToChecklist() {
      if (this.subStep === 2) {
        this.measurementsComplete = true
      } else if (this.subStep === 1) {
        this.emergencyContactsComplete = true
      }
      this.subStep = 0
    },
    skip(){
      WebAnalytics.trackFlexible('Skipped Onboarding', {entityType: 'User', progress: this.progress})
      this.navigateToUserHome()
    },
    navigateToUserHome() {
      if (this.isFromFlow) {
        this.$router.push({name: 'onboarding', query: {userStatus: this.completed ? 'completed' : 'skip'}})
      } else {
        this.$router.push({name: 'user-overview'})
      }
    },
    finish() {
      WebAnalytics.trackFlexible('Finished Onboarding', {entityType: 'User'})
      this.$router.push({name: 'user-overview'})
    },
    keepGoing() {
      this.navigateToUserHome()
    },
    verifyPhone() {
      if (this.hasUnverified) {
        bus.emit('modals:phoneverify:show', this.userStore.profile.phoneNumbers.filter(p => !p.isVerified && p.canReceiveTexts)[0])
      }
    },
    verifyEmail() {
      EmailAddressService.requestVerification(this.userStore.profile.emailAddresses.filter(e => !e.isVerified)[0].emailAddressId).then(() => {
        Swal.fire('', 'Please check your email for a verification link.')
        this.emailVerificationRequested = true
      })
    },
    requestCropper(xhr) {
      bus.emit('modals:cropper:open', {
        blob: xhr.file,
        requester: 'modals:cropper:handle:user-onboard',
        isUserPhoto: true
      })
    },
    uploadPhoto(blob) {
      this.photoIsLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      UserService.uploadPhoto(formData).then(() => {
        this.userStore.fetchUser()
      }).finally(() => {
        this.photoIsLoading = false
        this.photoStepComplete = true
      })
    },
    requestAccess() {
      bus.emit('modals:request:show')
    },
    fetchContacts() {
      EmergencyContactService.list().then(response => {
        this.emergencyContacts = response.data
      })
    },
    refetchProfile() {
      this.userStore.fetchUser()
    },
    saveMeasurements() {
      bus.emit('measurements:save')
      this.measurementsComplete = true
    },
    viewInvitation(invitation: Invitation) {
      bus.emit('modals:invitations:view', invitation)
    },
    addContact() {
      bus.emit('modals:emergency:add')
    }
  },
  computed: {
    headerTitle(): string {
      switch (this.subStep){
        case 0:
          return `Welcome, ${this.userStore.profile.firstName}!`
        case 1:
          return 'Emergency Contacts'
        default:
          return `Let's edit your profile`
      }
    },
    hasNoPhone(): boolean {
      return this.userStore.profile.phoneNumbers.length === 0
    },
    hasUnverified(): boolean {
      return this.userStore.profile.phoneNumbers.filter(p => !p.isVerified && p.canReceiveTexts).length > 0
    },
    hasUnverifiedEmail(): boolean {
      return !this.emailVerificationRequested && this.userStore.profile.emailAddresses.filter(p => !p.isVerified).length > 0
    },
    hasVerified(): boolean {
      return this.userStore.profile.phoneNumbers.filter(p => p.isVerified && p.canReceiveTexts).length > 0
    },
    hasMemberships(): boolean {
      return this.userStore.productions.filter(p => p.status === MembershipStatus.Active || p.status === MembershipStatus.PendingEntity).length > 0 ||
          this.userStore.organizations.filter(o => o.status === MembershipStatus.Active || o.status === MembershipStatus.PendingEntity).length > 0
    },
    completed(): boolean {
      //return this.measurementsComplete &&
      return (this.emergencyContacts.length > 0 || this.emergencyContactsComplete) &&
          this.hasMemberships &&
          !(this.userStore.profile.avatar === '' || this.userStore.profile.avatar === null) &&
          !this.hasNoPhone && !this.hasUnverified && !this.hasUnverifiedEmail
    },
    progress(){
      let totalCompleted = 0

      if (this.emergencyContacts.length > 0 || this.emergencyContactsComplete) {
        // this.$log.debug('Emergency Contacts YES')
        totalCompleted += 1
      }
      if (this.hasMemberships) {
        // this.$log.debug('Has Memberships YES')
        totalCompleted += 1
      }
      if (!this.hasUnverifiedEmail) {
        // this.$log.debug('Email Verified YES')
        totalCompleted += 1
      }
      if (!this.hasNoPhone && !this.hasUnverified) {
        // this.$log.debug('Phone Verified YES')
        totalCompleted += 1
      }
      if (this.userStore.profile.avatar && this.userStore.profile.avatar !== '') {
        // this.$log.debug('Avatar YES')
        totalCompleted += 1
      }

      const total = 6
      return (totalCompleted / total) * 100
    },
    isFromFlow(): boolean {
      return !!this.$route.query.fromFlow
    }
  }
})
